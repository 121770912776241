<template>
  <div>
    <market-form ref="marketForm" :is-loading="isLoading" @submit="submit" />
  </div>
</template>

<script>
import MarketForm from "@/views/Panel/views/Markets/components/MarketForm";
import { mapActions } from "vuex";
export default {
  name: "NewMarket",
  components: { MarketForm },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      createMarket: "api/management/market/createMarket"
    }),
    submit(data) {
      this.isLoading = true;

      this.createMarket(data)
        .then(() => {
          this.$root.$emit("refresh-market-list");
          this.$refs.marketForm.handleCancel();
          this.$notifier.notify({
            message: "Market created successfully",
            color: "success"
          });
        })
        .catch(error => {
          this.$notifier.notify({
            message: "Cannot create market",
            color: "error"
          });
          console.log({ error });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
